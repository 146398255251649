import { FC } from "react";

const Logout: FC = () => {
    // const dispatch = useDispatch();

    // firebaseAuth.signOut()
    //     .then(() => {
    //         dispatch(logout());

    //         navigate("/");
    //     })
    //     .catch(error => {
    //         dispatch(addToast("danger", error.message));
    //     });

    return null;
};

export default Logout;
